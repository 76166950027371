import React from "react";
import StarsByRating from "../stars-by-rating/stars-by-rating";
import { getAverageRating, roundHalf } from "../../helpers/helpers";
import { TableRow } from "../wine-details/wine-details-components";
import {
  useCurrentWineIsReviewedByCurrentUser,
  useReviewsForCurrentWine,
  useWine,
  useFilters,
} from "../../helpers/hooks";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import { normalizeNumber } from "../../helpers/helpers";
import Highlighter from "react-highlight-words";
import _ from "lodash";

export const WineHeader = (props) => {
  const { country, region, name } = props.wine;
  const { searchCriteria } = useFilters();

  return (
    <div className="wine-header">
      <div className="wine-country">
        {country}
        {region && `, ${region}`}
      </div>

      <div className="wine-name-container">
        <div className="wine-name">
          <Highlighter
            highlightClassName="highlight"
            searchWords={[searchCriteria]}
            autoEscape={true}
            sanitize={(name) => _.deburr(name)}
            textToHighlight={name}
          />
        </div>
      </div>
    </div>
  );
};

export const WineImage = (props) => {
  const { id, name } = props.wine;

  return (
    <img
      src={`https://images.alko.fi/images/cs_srgb,f_auto,t_products/cdn/${id}/kuva.jpg`}
      alt={`kuva ${name}`}
    />
  );
};

export const WinePrice = (props) => {
  const { price } = props.wine;

  return (
    <div className="wine-price">
      {price.split(".")[0]}
      <span className="cents">{price.split(".")[1]}</span>
    </div>
  );
};

export const WineDescriptionLabel = (props) => {
  const { description } = props.wine;

  return (
    <div
      className={`wine-description-label ${description
        .split(",")[1]
        .toLowerCase()
        .trimStart()
        .replace(" ", "-")}`}
    >
      {description.split(",")[1]}
    </div>
  );
};

export const WineIcons = (props) => {
  const { specialGroup } = props.wine;

  return (
    <div className="wine-icons">
      {specialGroup === "soveltuu vegaaneille" && (
        <img
          className="wine-icon"
          src="/vegetarian-mark.svg"
          alt="Soveltuu vegaaneille"
        />
      )}
      {specialGroup === "luomu" && (
        <img className="wine-icon" src="/organic-mark.svg" alt="Luomu" />
      )}
    </div>
  );
};

export const WineScore = (props) => {
  const { averageRating, review, id } = props.wine;
  const { reviews } = useSelector((state) => state.user);
  const isReviewedByCurrentUser = reviews.map((wine) => wine.id).includes(id);
  const match = useRouteMatch();
  const isReviewsPage = match.path === "/user/reviews";
  const ratingToDisplay = isReviewsPage
    ? review.rating
    : roundHalf(averageRating);

  return (
    <div className="score">
      <StarsByRating
        rating={ratingToDisplay}
        isReviewedByCurrentUser={isReviewedByCurrentUser}
      />
    </div>
  );
};

export const WineDetailsContent = () => {
  const wine = useWine();
  const allReviews = useReviewsForCurrentWine();
  const isReviewedByCurrentUser = useCurrentWineIsReviewedByCurrentUser();

  let ratingsText;
  if (allReviews.length > 1) {
    ratingsText = `${allReviews.length} arviota`;
  } else if (allReviews.length === 1) {
    ratingsText = `${allReviews.length} arvio`;
  } else {
    ratingsText = "";
  }

  return (
    <div className="wrapper">
      <div className="image-details-wrapper">
        <div className="image-container">
          <img
            src={`https://images.alko.fi/images/cs_srgb,f_auto,t_medium/cdn/${wine.id}/kuva.jpg`}
            alt={`kuva ${wine.name}`}
          />
          <div className="copyright">Copyright &copy; Alko.fi</div>
          <div className="links">
            <a
              href={`https://www.alko.fi/tuotteet/${wine.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Tuote alkon sivuilla
            </a>
          </div>
        </div>

        <div className="details-container">
          <div className="details">
            <h3>{wine.name}</h3>

            <StarsByRating
              rating={roundHalf(getAverageRating(allReviews))}
              isReviewedByCurrentUser={isReviewedByCurrentUser}
              exactRating={ratingsText}
            />

            <div className="country">
              {wine.country}
              {wine.region && `, ${wine.region}`}
            </div>

            <div className="price">{normalizeNumber(wine.price)}€</div>

            <div className="table">
              <TableRow title="Vuosi" data={wine.year} />
              <TableRow title="Rypäleet" data={wine.grapes.join(", ")} />
              <TableRow
                title="Pakkauskoko"
                data={normalizeNumber(wine.bottleSize)}
              />
              <TableRow title="Merkinnät" data={wine.notes} />
              <TableRow title="Luonnehdinta" data={wine.description} />
              <TableRow
                title="Alkoholi"
                data={normalizeNumber(wine.alcohol)}
                description={"%"}
              />
              <TableRow
                title="Sokeri"
                data={normalizeNumber(wine.sugar)}
                description={"g/l"}
              />
              <TableRow
                title="Hapot"
                data={normalizeNumber(wine.acids)}
                description={"g/l"}
              />
              <TableRow title="Valikoima" data={wine.selection} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const WineSelection = ({ selection }) => {
  return <div className={`${selection} wine-selection`}>{selection}</div>;
};
